@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@import 'assets/scss/themes/mytheme/theme.scss';
@import 'primeng/resources/primeng.css';


.xng-breadcrumb-separator {
	padding: 0 4px;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.xng-breadcrumb-root {
	color: #242A3C !important;
	opacity: 0.5;
}

.xng-breadcrumb-item {
	font-size: 0.875rem;
	line-height: 1.25rem;
}